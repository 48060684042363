import IMG_0161_480w from '../../images/nightAtTheOrfelia/IMG_0161.jpeg?width=480';
import IMG_0161_800w from '../../images/nightAtTheOrfelia/IMG_0161.jpeg?width=800';
import IMG_0166_480w from '../../images/nightAtTheOrfelia/IMG_0166.jpeg?width=480';
import IMG_0166_800w from '../../images/nightAtTheOrfelia/IMG_0166.jpeg?width=800';
import IMG_0180_480w from '../../images/nightAtTheOrfelia/IMG_0180.jpeg?width=480';
import IMG_0180_800w from '../../images/nightAtTheOrfelia/IMG_0180.jpeg?width=800';
import IMG_0185_480w from '../../images/nightAtTheOrfelia/IMG_0185.jpeg?width=480';
import IMG_0185_800w from '../../images/nightAtTheOrfelia/IMG_0185.jpeg?width=800';
import IMG_0189_480w from '../../images/nightAtTheOrfelia/IMG_0189.jpeg?width=480';
import IMG_0189_800w from '../../images/nightAtTheOrfelia/IMG_0189.jpeg?width=800';
import IMG_0200_480w from '../../images/nightAtTheOrfelia/IMG_0200.jpeg?width=480';
import IMG_0200_800w from '../../images/nightAtTheOrfelia/IMG_0200.jpeg?width=800';
import IMG_0205_480w from '../../images/nightAtTheOrfelia/IMG_0205.jpeg?width=480';
import IMG_0205_800w from '../../images/nightAtTheOrfelia/IMG_0205.jpeg?width=800';
import IMG_0228_480w from '../../images/nightAtTheOrfelia/IMG_0228.jpeg?width=480';
import IMG_0228_800w from '../../images/nightAtTheOrfelia/IMG_0228.jpeg?width=800';
import IMG_0231_480w from '../../images/nightAtTheOrfelia/IMG_0231.jpeg?width=480';
import IMG_0231_800w from '../../images/nightAtTheOrfelia/IMG_0231.jpeg?width=800';
import IMG_0243_480w from '../../images/nightAtTheOrfelia/IMG_0243.jpeg?width=480';
import IMG_0243_800w from '../../images/nightAtTheOrfelia/IMG_0243.jpeg?width=800';
import IMG_0248_480w from '../../images/nightAtTheOrfelia/IMG_0248.jpeg?width=480';
import IMG_0248_800w from '../../images/nightAtTheOrfelia/IMG_0248.jpeg?width=800';
import IMG_0255_480w from '../../images/nightAtTheOrfelia/IMG_0255.jpeg?width=480';
import IMG_0255_800w from '../../images/nightAtTheOrfelia/IMG_0255.jpeg?width=800';
import IMG_0259_480w from '../../images/nightAtTheOrfelia/IMG_0259.jpeg?width=480';
import IMG_0259_800w from '../../images/nightAtTheOrfelia/IMG_0259.jpeg?width=800';
import IMG_0260_480w from '../../images/nightAtTheOrfelia/IMG_0260.jpeg?width=480';
import IMG_0260_800w from '../../images/nightAtTheOrfelia/IMG_0260.jpeg?width=800';
import IMG_0264_480w from '../../images/nightAtTheOrfelia/IMG_0264.jpeg?width=480';
import IMG_0264_800w from '../../images/nightAtTheOrfelia/IMG_0264.jpeg?width=800';
import IMG_0274_480w from '../../images/nightAtTheOrfelia/IMG_0274.jpeg?width=480';
import IMG_0274_800w from '../../images/nightAtTheOrfelia/IMG_0274.jpeg?width=800';
import IMG_0275_480w from '../../images/nightAtTheOrfelia/IMG_0275.jpeg?width=480';
import IMG_0275_800w from '../../images/nightAtTheOrfelia/IMG_0275.jpeg?width=800';
import IMG_0281_480w from '../../images/nightAtTheOrfelia/IMG_0281.jpeg?width=480';
import IMG_0281_800w from '../../images/nightAtTheOrfelia/IMG_0281.jpeg?width=800';
import IMG_0282_480w from '../../images/nightAtTheOrfelia/IMG_0282.jpeg?width=480';
import IMG_0282_800w from '../../images/nightAtTheOrfelia/IMG_0282.jpeg?width=800';
import IMG_0285_480w from '../../images/nightAtTheOrfelia/IMG_0285.jpeg?width=480';
import IMG_0285_800w from '../../images/nightAtTheOrfelia/IMG_0285.jpeg?width=800';
import IMG_0290_480w from '../../images/nightAtTheOrfelia/IMG_0290.jpeg?width=480';
import IMG_0290_800w from '../../images/nightAtTheOrfelia/IMG_0290.jpeg?width=800';
import IMG_0314_480w from '../../images/nightAtTheOrfelia/IMG_0314.jpeg?width=480';
import IMG_0314_800w from '../../images/nightAtTheOrfelia/IMG_0314.jpeg?width=800';
import IMG_0338_480w from '../../images/nightAtTheOrfelia/IMG_0338.jpeg?width=480';
import IMG_0338_800w from '../../images/nightAtTheOrfelia/IMG_0338.jpeg?width=800';
import IMG_0347_480w from '../../images/nightAtTheOrfelia/IMG_0347.jpeg?width=480';
import IMG_0347_800w from '../../images/nightAtTheOrfelia/IMG_0347.jpeg?width=800';
import IMG_0367_480w from '../../images/nightAtTheOrfelia/IMG_0367.jpeg?width=480';
import IMG_0367_800w from '../../images/nightAtTheOrfelia/IMG_0367.jpeg?width=800';
import IMG_0391_480w from '../../images/nightAtTheOrfelia/IMG_0391.jpeg?width=480';
import IMG_0391_800w from '../../images/nightAtTheOrfelia/IMG_0391.jpeg?width=800';
import IMG_0394_480w from '../../images/nightAtTheOrfelia/IMG_0394.jpeg?width=480';
import IMG_0394_800w from '../../images/nightAtTheOrfelia/IMG_0394.jpeg?width=800';
import IMG_0403_480w from '../../images/nightAtTheOrfelia/IMG_0403.jpeg?width=480';
import IMG_0403_800w from '../../images/nightAtTheOrfelia/IMG_0403.jpeg?width=800';
import IMG_0405_480w from '../../images/nightAtTheOrfelia/IMG_0405.jpeg?width=480';
import IMG_0405_800w from '../../images/nightAtTheOrfelia/IMG_0405.jpeg?width=800';
import IMG_0438_480w from '../../images/nightAtTheOrfelia/IMG_0438.jpeg?width=480';
import IMG_0438_800w from '../../images/nightAtTheOrfelia/IMG_0438.jpeg?width=800';
import IMG_0448_480w from '../../images/nightAtTheOrfelia/IMG_0448.jpeg?width=480';
import IMG_0448_800w from '../../images/nightAtTheOrfelia/IMG_0448.jpeg?width=800';

export default [
  {
    srcset:`${IMG_0161_480w}  480w, ${IMG_0161_800w} 800w`
  },
  {
    srcset:`${IMG_0166_480w}  480w, ${IMG_0166_800w} 800w`
  },
  {
    srcset:`${IMG_0180_480w}  480w, ${IMG_0180_800w} 800w`
  },
  {
    srcset:`${IMG_0185_480w}  480w, ${IMG_0185_800w} 800w`
  },
  {
    srcset:`${IMG_0189_480w}  480w, ${IMG_0189_800w} 800w`
  },
  {
    srcset:`${IMG_0200_480w}  480w, ${IMG_0200_800w} 800w`
  },
  {
    srcset:`${IMG_0205_480w}  480w, ${IMG_0205_800w} 800w`
  },
  {
    srcset:`${IMG_0228_480w}  480w, ${IMG_0228_800w} 800w`
  },
  {
    srcset:`${IMG_0231_480w}  480w, ${IMG_0231_800w} 800w`
  },
  {
    srcset:`${IMG_0243_480w}  480w, ${IMG_0243_800w} 800w`
  },
  {
    srcset:`${IMG_0248_480w}  480w, ${IMG_0248_800w} 800w`
  },
  {
    srcset:`${IMG_0255_480w}  480w, ${IMG_0255_800w} 800w`
  },
  {
    srcset:`${IMG_0259_480w}  480w, ${IMG_0259_800w} 800w`
  },
  {
    srcset:`${IMG_0260_480w}  480w, ${IMG_0260_800w} 800w`
  },
  {
    srcset:`${IMG_0264_480w}  480w, ${IMG_0264_800w} 800w`
  },
  {
    srcset:`${IMG_0274_480w}  480w, ${IMG_0274_800w} 800w`
  },
  {
    srcset:`${IMG_0275_480w}  480w, ${IMG_0275_800w} 800w`
  },
  {
    srcset:`${IMG_0281_480w}  480w, ${IMG_0281_800w} 800w`
  },
  {
    srcset:`${IMG_0282_480w}  480w, ${IMG_0282_800w} 800w`
  },
  {
    srcset:`${IMG_0285_480w}  480w, ${IMG_0285_800w} 800w`
  },
  {
    srcset:`${IMG_0290_480w}  480w, ${IMG_0290_800w} 800w`
  },
  {
    srcset:`${IMG_0314_480w}  480w, ${IMG_0314_800w} 800w`
  },
  {
    srcset:`${IMG_0338_480w}  480w, ${IMG_0338_800w} 800w`
  },
  {
    srcset:`${IMG_0347_480w}  480w, ${IMG_0347_800w} 800w`
  },
  {
    srcset:`${IMG_0367_480w}  480w, ${IMG_0367_800w} 800w`
  },
  {
    srcset:`${IMG_0391_480w}  480w, ${IMG_0391_800w} 800w`
  },
  {
    srcset:`${IMG_0394_480w}  480w, ${IMG_0394_800w} 800w`
  },
  {
    srcset:`${IMG_0403_480w}  480w, ${IMG_0403_800w} 800w`
  },
  {
    srcset:`${IMG_0405_480w}  480w, ${IMG_0405_800w} 800w`
  },
  {
    srcset:`${IMG_0438_480w}  480w, ${IMG_0438_800w} 800w`
  },
  {
    srcset:`${IMG_0448_480w}  480w, ${IMG_0448_800w} 800w`
  }
]